<template>
  <div class="newUtente" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Nuovo Utente
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-select
                              :items="['Baggi','LaCelle']"
                              v-model="appartenenzaCliente"
                              v-if="permesso == 'Cliente' && (ruolo == 'Master' || ruolo == 'Tecnico')"
                              required
                              filled
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Appartenenza"
                              class="mb-2"
                          ></v-select>
                          <v-text-field
                              autocomplete="off"
                              label="Nominativo 1"
                              v-model="nominativo1"
                              required
                              :rules="nominativo1Rules"
                          ></v-text-field>
                          <v-text-field
                              autocomplete="off"
                              label="Nominativo 2"
                              v-model="nominativo2"
                              required
                              :rules="nominativo2Rules"
                          ></v-text-field>
                          <v-text-field
                              autocomplete="off"
                              label="Email"
                              type="email"
                              v-model="email"
                              required
                              :rules="emailRules"
                          ></v-text-field>
                          <v-text-field
                              autocomplete="off"
                              label="Telefono"
                              v-model="telefono"
                              required
                              :rules="telefonoRules"
                          ></v-text-field>
                          <v-text-field
                              label="Codice Fiscale"
                              v-model="codiceFiscale"
                              :rules="codiceFiscaleRules"
                          ></v-text-field>
                          <v-text-field
                              label="Partita Iva"
                              v-model="partitaIva"
                              :rules="partitaIvaRules"
                          ></v-text-field>
                          <v-textarea
                              autocomplete="off"
                              label="Note"
                              v-model="note"
                              outlined
                              auto-grow
                              rows="1"
                          ></v-textarea>
                          <v-checkbox label="Crea Accesso" dense v-model="accesso" @change="password = '';"></v-checkbox>
                          <v-text-field
                              :disabled="!accesso"
                              autocomplete="off"
                              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPwd ? 'text' : 'password'"
                              label="Password"
                              class="mb-0"
                              v-model="password"
                              @click:append="showPwd = !showPwd"
                          ></v-text-field>
                          <v-select
                              :items="lista_permessi"
                              v-model="permesso"
                              required
                              filled
                              :rules="requiredRules"
                              label="Permessi"
                              class="mb-0"
                          ></v-select>
                          <v-expansion-panels v-if="false && (permesso == 'Cliente' || !permesso)">
                            <v-expansion-panel>
                              <v-expansion-panel-header disable-icon-rotate>
                                Stabilimenti
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary mr-2"
                                  class="white--text"
                                  fab
                                  x-small
                                  right
                                  @click="modalNewStabilimento = true;"
                                  max-width="32px"
                                >
                                  <v-icon small>
                                    fas fa-plus
                                  </v-icon>
                                </v-btn>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list
                                  subheader
                                  two-line
                                >
                                  <v-list-item
                                    v-for="stabilimento of stabilimenti"
                                    :key="stabilimento._id"
                                  >
                                    <v-list-item-avatar>
                                      <v-icon
                                        class="grey lighten-1"
                                        dark
                                      >
                                        fas fa-building
                                      </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>{{ stabilimento.indirizzo }}</v-list-item-title>

                                      <v-list-item-subtitle>{{ stabilimento.note }}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-btn icon>
                                        <v-icon color="grey lighten-1">fas fa-question</v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-container grid-list-sm fluid>
                  <v-row>
                    <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                      <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      width="500"
      v-model="modalNewStabilimento"
    >
      <NewStabilimento v-if="modalNewStabilimento" @refreshStabilimenti="refreshListStabilimenti"/>
    </v-dialog>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import { emailRules, signupPasswordRules, nominativo1Rules, nominativo2Rules, telefonoRules, requiredRules, codiceFiscaleRules, partitaIvaRules } from '@/js/validationRules';
const NewStabilimento = () => import('@/components/NewStabilimento');

export default {
  name: 'newUtente',
  components: {
    NewStabilimento
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      appartenenzaCliente: 'LaCelle',
      email: '',
      telefono: '',
      nominativo1: '',
      nominativo2: '',
      codiceFiscale: '',
      partitaIva: '',
      password: '',
      note: '',
      formValid: false,
      emailRules: emailRules,
      passwordRules: signupPasswordRules,
      nominativo1Rules: nominativo1Rules,
      nominativo2Rules: nominativo2Rules,
      codiceFiscaleRules: codiceFiscaleRules,
      partitaIvaRules: partitaIvaRules,
      telefonoRules: telefonoRules,
      requiredRules: requiredRules,
      accesso: false,
      loading: false,
      showPwd: false,
      lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
      permesso: undefined,
      stabilimenti: [],
      modalNewStabilimento: false,
    }
  },
  watch:{
    ruolo() {
      if(this.ruolo == 'Partner') {
        this.appartenenzaCliente = 'Baggi';
        this.lista_permessi = ['Cliente'];
      } else if(this.ruolo == 'Tecnico') this.lista_permessi = ['Cliente'];
    }
  },
  created() {
    if(this.ruolo == 'Partner') {
      this.appartenenzaCliente = 'Baggi';
      this.lista_permessi = ['Cliente'];
    } else if(this.ruolo == 'Tecnico') this.lista_permessi = ['Cliente'];
  },
  methods: {
    async submit(){
      this.loading = true;
      if(
        this.$refs.form_data.validate() &&
        this.formValid
      ){
        let data = {
          appartenenza: this.appartenenzaCliente,
          accesso: this.accesso,
          email: this.email,
          telefono: this.telefono,
          nominativo1: this.nominativo1,
          nominativo2: this.nominativo2,
          partitaIva: this.partitaIva,
          codiceFiscale: this.codiceFiscale,
          password: this.password,
          permesso: this.permesso,
          note: this.note,
          stabilimenti: this.stabilimenti,
        };
        let newUtente = await apiUtenti.insertNew(this.token, data);
        if(newUtente == 'sameMail') alert('Email già esistente!');
        else this.$router.push('/listUtenti');
      }
      this.loading = false;
    },
    async refreshListStabilimenti(stabilimento){
      this.modalNewStabilimento = false;
      if(stabilimento) this.stabilimenti.push(stabilimento);
    }
  },
}
</script>
